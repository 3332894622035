:root {
  --bg-custom-red: #c00000;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  max-width: 1250px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

/* Tailwind não possui classes específicas para switches, então vamos criar nosso próprio estilo */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
img.react-parallax-bgimage{
  object-fit: cover;
}
.react-multiple-carousel__arrow::before{
  color: #888888 !important;
  font-weight: 700;
  font-size: 2rem !important;
}
button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right, button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
  background-color: transparent !important;
}
body {
  padding-top: 3rem; 
}
@media screen and (max-width: 768px)  {
  body {
    padding-top: 4.3rem; 
  }
}

li.react-multi-carousel-item.react-multi-carousel-item--active{
  display: flex !important;
  justify-content: center !important;
}

.boldClass{
  color: red;
}
.no-bullets{
  list-style: none !important;
}
::selection {
  background-color: #c00000; 
  color: #ffffff; 
}