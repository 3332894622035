/* src/components/PricingSection.css */
.highlight {
  color: #3b82f6;
}

.pricing-card {
  background-color: white;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.popular-plan {
  border: 4px solid #3b82f6;
}

.popular-label {
  background-color: #3b82f6;
  color: white;
  padding: 5px;
  border-radius: 20px;
  font-size: 12px;
  margin-top: 10px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (min-width: 768px) {
  .pricing-card {
    margin: 0 10px;
  }
}
