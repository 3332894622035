/* src/components/TestimonialsSection.css */
.slick-slide img {
  width: 100%;
  height: auto;
}

.slick-prev:before,
.slick-next:before {
  color: white;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.bg-blue-400 {
  background-color: #60a5fa;
}

.bg-blue-600 {
  background-color: #2563eb;
}

.bg-blue-700 {
  background-color: #1e40af;
}

.text-white {
  color: #ffffff;
}

.flex-shrink-0 {
  flex-shrink: 0;
}
